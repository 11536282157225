import React from "react";

const SvgEu = props => (
  <svg viewBox="0 0 93.48 66.51" {...props}>
    <defs>
      <style>{".EU_svg__cls-2{fill:#fbee23}"}</style>
    </defs>
    <g id="EU_svg__Layer_2" data-name="Layer 2">
      <g id="EU_svg__Layer_1-2" data-name="Layer 1">
        <path fill="#2a4da1" d="M0 0h93.48v66.51H0z" />
        <path
          className="EU_svg__cls-2"
          d="M47.76 9.46H51l-2.62 2.17 1.02 3.45-2.61-2.13-2.61 2.13 1.03-3.45-2.64-2.17h3.24l.98-3.37.97 3.37zM47.87 53.81h3.26l-2.63 2.1 1.02 3.31-2.61-2.04-2.61 2.04 1.03-3.31-2.65-2.1h3.25l.98-3.23.96 3.23zM58.19 50.88h3.25l-2.62 2.09 1.02 3.31-2.61-2.05-2.61 2.05 1.03-3.31L53 50.88h3.24l.99-3.24.96 3.24zM58.19 12.42h3.25l-2.62 2.09 1.02 3.31-2.61-2.04-2.61 2.04 1.03-3.31L53 12.42h3.24l.99-3.23.96 3.23zM65.84 20.56h3.25l-2.62 2.09 1.02 3.31-2.61-2.04-2.6 2.04 1.03-3.31-2.65-2.09h3.24l.98-3.23.96 3.23zM65.84 42.84h3.25l-2.62 2.1 1.02 3.31-2.61-2.04-2.6 2.04 1.03-3.31-2.65-2.1h3.24l.98-3.23.96 3.23zM68.71 31.53h3.26l-2.62 2.09 1.02 3.32-2.61-2.05-2.61 2.05 1.03-3.32-2.65-2.09h3.24l.99-3.23.95 3.23zM37.24 12.44h3.25l-2.62 2.1 1.02 3.31-2.61-2.05-2.62 2.05 1.03-3.31-2.64-2.1h3.24l.99-3.23.96 3.23zM29.79 20.59h3.26l-2.62 2.09L31.44 26l-2.61-2.05-2.6 2.05 1.02-3.32-2.65-2.09h3.25l.98-3.23.96 3.23zM27.03 31.56h3.25l-2.62 2.09 1.02 3.32-2.61-2.04-2.61 2.04 1.02-3.32-2.64-2.09h3.25l.98-3.23.96 3.23zM29.79 42.87h3.26l-2.62 2.09 1.01 3.32-2.61-2.04-2.6 2.04 1.02-3.32-2.65-2.09h3.25l.98-3.23.96 3.23zM37.35 50.9h3.25l-2.62 2.09 1.01 3.32-2.61-2.04-2.61 2.04 1.03-3.32-2.64-2.09h3.24l.98-3.23.97 3.23z"
        />
      </g>
    </g>
  </svg>
);

export default SvgEu;
