import React from "react";
import Plx from "react-plx";
import SvgTemperature from "./Icons/Temperature";
import SvgPrecipitation from "./Icons/Precipitation";
import SvgWindSpeed from "./Icons/WindSpeed";
import SvgSolarRadiation from "./Icons/SolarRadiation";
import SvgWindDirection from "./Icons/WindDirection";
import SvgSnow from "./Icons/Snow";

function StationText() {
  const parallaxData = [
    {
      start: ".station-parallax-trigger",
      duration: "40vh",
      properties: [
        {
          startValue: 100,
          endValue: 0,
          unit: "%",
          property: "translateY"
        },
        {
          startValue: 0,
          endValue: 1,
          property: "opacity"
        }
      ]
    },
    {
      start: ".station-parallax-trigger",
      startOffset: "80vh",
      duration: "40vh",
      properties: [
        {
          startValue: 0,
          endValue: -100,
          unit: "%",
          property: "translateY"
        }
      ]
    },
    {
      start: ".station-parallax-trigger",
      startOffset: "80vh",
      duration: "20vh",
      properties: [
        {
          startValue: 1,
          endValue: 0,
          property: "opacity"
        }
      ]
    }
  ];

  return (
    <Plx
      className="station-text-fixed"
      id="station-text1"
      parallaxData={parallaxData}
    >
      <div className="station-text">
        <h2>Senzori aer</h2>
        <p>
          Parametri măsurați prin eșantionare continuă, pe înălțimi și adâncimi
          diferite
        </p>
        <ul className="sensors-icons">
          <li className="sensor-icon">
            <SvgWindSpeed width="40px" height="40px" />
            <span>Viteza vântului</span>
          </li>
          <li className="sensor-icon">
            <SvgWindDirection width="40px" height="40px" />
            <span>Direcția vântului</span>
          </li>
          <li className="sensor-icon">
            <SvgTemperature width="40px" height="40px" />
            <span>Temperatură</span>
          </li>
          <li className="sensor-icon">
            <SvgSolarRadiation width="40px" height="40px" />
            <span>Radiație solară</span>
          </li>
          <li className="sensor-icon">
            <SvgPrecipitation width="40px" height="40px" />
            <span>Precipitații</span>
          </li>
          <li className="sensor-icon">
            <SvgSnow width="40px" height="40px" />
            <span>Strat zăpadă</span>
          </li>
        </ul>
        <p className="text-highlight">
          Urmărește și transmite nivelul precipitațiilor atât în timp real cât
          și prognozat pentru optimizarea planului de însămânțare și de
          recoltare
        </p>
      </div>
    </Plx>
  );
}

export default StationText;
