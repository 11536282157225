import React from "react";
import "./App.scss";

import Footer from "./Footer";
import Main from "./Main";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Crops from "./Crops";
import Nav from "./Nav";

function App() {
  return (
    <Router>
      <Route path="/crops" component={Crops}></Route>
      <Route path="/" exact component={Main}></Route>
      <Nav />
      <Footer></Footer>
    </Router>
  );
}

export default App;
