import React from "react";

const SvgAtmosphericPressure = props => (
  <svg viewBox="0 0 48 48" {...props}>
    <path
      d="M24 2C11.8 2 2 11.8 2 24s9.8 22 22 22 22-9.8 22-22S36.2 2 24 2zm-8.8 30.7l.9-.5-.1 3-2.6-1.5.9-.5c-1.1-2.5-2.2-6.9 1.3-10.4 4.8-4.8 0-11.3-.1-11.4l.8-.6c.1.1 5.3 7.4-.1 12.7-2.8 3-2.2 6.6-1 9.2zm3.8 0l.9-.5-.1 3-2.6-1.5.9-.5c-1.1-2.5-2.2-6.9 1.3-10.4 4.7-4.7.1-11.1-.1-11.4l.8-.6c.1.1 5.3 7.4-.1 12.7-2.7 3-2.1 6.6-1 9.2zm5.1-9.2c-3 3-2.3 6.5-1.2 9.2l.9-.5-.1 3-2.6-1.5.9-.5c-1.1-2.5-2.2-6.9 1.3-10.4 4.7-4.7.1-11.1-.1-11.4l.8-.6c.2.1 5.4 7.4.1 12.7zm10.7 12.2H29v-1h4.8V30h-3.2v-1h3.2v-5.3H29v-1h4.8v-5h-3.2v-1h3.2v-5H29v-1h5.8v25z"
      fill="#2d353c"
    />
  </svg>
);

export default SvgAtmosphericPressure;
