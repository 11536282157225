import React from "react";
import Plx from "react-plx";

function DeviceParallax() {
  const parallaxFrame = [
    {
      start: ".device-parallax-trigger",
      duration: "60vh",
      properties: [
        {
          startValue: 80,
          endValue: 0,
          unit: "%",
          property: "translateY"
        },
        {
          startValue: 0,
          endValue: 1,
          property: "opacity"
        }
      ]
    },
    {
      start: ".device-parallax-trigger",
      startOffset: "380vh",
      duration: "60vh",
      properties: [
        {
          startValue: 0,
          endValue: -100,
          unit: "%",
          property: "translateY"
        },
        {
          startValue: 1,
          endValue: 0,
          property: "opacity"
        }
      ]
    }
  ];
  const parallaxImg1 = [
    {
      start: ".device-parallax-trigger",
      duration: "60vh",
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: "opacity"
        }
      ]
    },
    {
      start: ".device-parallax-trigger",
      startOffset: "80vh",
      duration: "40vh",
      properties: [
        {
          startValue: 1,
          endValue: 0,
          property: "opacity"
        }
      ]
    }
  ];
  const parallaxImg2 = [
    {
      start: ".device-parallax-trigger",
      startOffset: "118vh",
      duration: "40vh",
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: "opacity"
        }
      ]
    },
    {
      start: ".device-parallax-trigger",
      startOffset: "180vh",
      duration: "40vh",
      properties: [
        {
          startValue: 1,
          endValue: 0,
          property: "opacity"
        }
      ]
    }
  ];
  const parallaxImg3 = [
    {
      start: ".device-parallax-trigger",
      startOffset: "220vh",
      duration: "40vh",
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: "opacity"
        }
      ]
    },
    {
      start: ".device-parallax-trigger",
      startOffset: "280vh",
      duration: "40vh",
      properties: [
        {
          startValue: 1,
          endValue: 0,
          property: "opacity"
        }
      ]
    }
  ];
  const parallaxImg4 = [
    {
      start: ".device-parallax-trigger",
      startOffset: "320vh",
      duration: "40vh",
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: "opacity"
        }
      ]
    }
  ];
  const parallaxText1 = [
    {
      start: ".device-parallax-trigger",
      startOffset: "80vh",
      duration: "40vh",
      properties: [
        {
          startValue: 0,
          endValue: -15,
          unit: "%",
          property: "translateY"
        },
        {
          startValue: 1,
          endValue: 0,
          property: "opacity"
        }
      ]
    }
  ];
  const parallaxText2 = [
    {
      start: ".device-parallax-trigger",
      startOffset: "120vh",
      duration: "40vh",
      properties: [
        {
          startValue: 25,
          endValue: 0,
          unit: "%",
          property: "translateY"
        },
        {
          startValue: 0,
          endValue: 1,
          property: "opacity"
        }
      ]
    },
    {
      start: ".device-parallax-trigger",
      startOffset: "180vh",
      duration: "40vh",
      properties: [
        {
          startValue: 0,
          endValue: -15,
          unit: "%",
          property: "translateY"
        },
        {
          startValue: 1,
          endValue: 0,
          property: "opacity"
        }
      ]
    }
  ];
  const parallaxText3 = [
    {
      start: ".device-parallax-trigger",
      startOffset: "220vh",
      duration: "40vh",
      properties: [
        {
          startValue: 25,
          endValue: 0,
          unit: "%",
          property: "translateY"
        },
        {
          startValue: 0,
          endValue: 1,
          property: "opacity"
        }
      ]
    },
    {
      start: ".device-parallax-trigger",
      startOffset: "280vh",
      duration: "40vh",
      properties: [
        {
          startValue: 0,
          endValue: -15,
          unit: "%",
          property: "translateY"
        },
        {
          startValue: 1,
          endValue: 0,
          property: "opacity"
        }
      ]
    }
  ];
  const parallaxText4 = [
    {
      start: ".device-parallax-trigger",
      startOffset: "320vh",
      duration: "40vh",
      properties: [
        {
          startValue: 25,
          endValue: 0,
          unit: "%",
          property: "translateY"
        },
        {
          startValue: 0,
          endValue: 1,
          property: "opacity"
        }
      ]
    }
  ];
  return (
    <Plx className="device-container-fixed" parallaxData={parallaxFrame}>
      <h2 className="device-title">
        Direct de pe telefonul
        <br /> tău mobil
      </h2>
      <div className="device-container">
        <img
          className="device-bg-pattern"
          src={require("./Img/sysLeaf.svg")}
          alt="logo syswin solutions"
        />
        <img src={require("./Img/device_frame.png")} alt="phone mockup" />
        <Plx className="device-img" parallaxData={parallaxImg1}>
          <img src={require("./Img/appmobile_1.png")} alt="sysagria station" />
        </Plx>
        <Plx className="device-img" parallaxData={parallaxImg2}>
          <img src={require("./Img/appmobile_2.png")} alt="sysagria station" />
        </Plx>
        <Plx className="device-img" parallaxData={parallaxImg3}>
          <img src={require("./Img/appmobile_3.png")} alt="sysagria station" />
        </Plx>
        <Plx className="device-img" parallaxData={parallaxImg4}>
          <img src={require("./Img/appmobile_4.png")} alt="sysagria station" />
        </Plx>
      </div>
      <div className="device-container-text">
        <Plx className="device-text" parallaxData={parallaxText1}>
          <p>
            Configurabilă pentru orice tip de cultură, inclusiv vizualizare
            multicultură pentru unul sau mai mulți utilizatori
          </p>
        </Plx>
        <Plx className="device-text" parallaxData={parallaxText2}>
          <p>
            Vizualizarea în timp real a tuturor datelor înregistrate de senzori,
            a stării dispozitivelor și a datelor istorice începând cu momentul
            instalării
          </p>
        </Plx>
        <Plx className="device-text" parallaxData={parallaxText3}>
          <p>
            Rapoarte și analize multidimensionale ale evoluției parametrilor
            măsurați în aer și în sol
          </p>
        </Plx>
        <Plx className="device-text" parallaxData={parallaxText4}>
          <p>
            Hărți izometrice create prin interpolarea datelor înregistrate
            pentru precipitații, umiditate, temperatură și alți parametri
            relevanți
          </p>
        </Plx>
      </div>
    </Plx>
  );
}
export default DeviceParallax;
