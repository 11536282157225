import React from "react";

const SvgRelativeHumidity = props => (
  <svg viewBox="0 0 48 48" {...props}>
    <path
      d="M24 2C11.8 2 2 11.8 2 24s9.8 22 22 22 22-9.8 22-22S36.2 2 24 2zm8.1 25.9c-.2 2-1.2 3.8-2.7 5.2-1.5 1.4-3.4 2-5.4 2-1.9 0-3.9-.7-5.4-2.1-1.5-1.3-2.5-3.2-2.7-5.2-.2-2 .3-4 1.5-5.6l6.2-9.2c.1-.1.2-.2.4-.2.1 0 .3.1.4.2l6.2 9.2c1.2 1.7 1.7 3.7 1.5 5.7zm-9.3-.8c.3-.4.4-1 .4-1.7s-.2-1.3-.5-1.7c-.3-.4-.7-.6-1.3-.6-1.2 0-1.7.8-1.7 2.3 0 .7.2 1.3.5 1.7.3.4.7.6 1.3.6.5 0 1-.2 1.3-.6zm-1.7-.8c-.1-.2-.1-.5-.1-1 0-.4 0-.7.1-.9.1-.2.2-.3.4-.3.3 0 .5.4.5 1.3 0 .8-.2 1.3-.5 1.3-.2-.1-.3-.2-.4-.4zm5.5-.3c-1.2 0-1.7.8-1.7 2.3 0 .7.1 1.3.5 1.7.3.4.7.6 1.3.6.6 0 1-.2 1.3-.6.3-.4.4-1 .4-1.7s-.2-1.3-.5-1.7c-.3-.4-.8-.6-1.3-.6zm0 3.6c-.2 0-.3-.1-.4-.3-.1-.2-.1-.5-.1-1 0-.4 0-.7.1-.9.1-.2.2-.3.4-.3.3 0 .5.4.5 1.3 0 .7-.2 1.2-.5 1.2zm.1-6.4l-4.1 7.3h-1.2l4.1-7.3h1.2z"
      fill="#2d353c"
    />
  </svg>
);

export default SvgRelativeHumidity;
