import React from "react";

const SvgPrecipitation = props => (
  <svg viewBox="0 0 48 48" {...props}>
    <path
      d="M46 24c0 12.2-9.8 22-22 22S2 36.2 2 24 11.8 2 24 2s22 9.8 22 22m-14.7-8.3h-.7c-.9-3.3-3.9-5.7-7.4-5.7-4.2 0-7.7 3.5-7.7 7.9 0 .4 0 .7.1 1.1-2.3.4-4 2.4-4 4.8 0 2.7 2.1 4.9 4.7 4.9h15c3.5 0 6.3-2.9 6.3-6.5s-2.8-6.5-6.3-6.5M16.1 30.3l-3.2 4.5 1.1.8 3.2-4.5-1.1-.8zm16.6-.1l-3.2 4.5 1.1.8 3.2-4.5-1.1-.8zm-12.3 0l-4.9 6.9 1.1.8 4.9-6.9-1.1-.8zm4.3.2l-4.2 5.9 1.1.8 4.2-5.9-1.1-.8zm4.3-.3l-5 7.1 1.1.8 5-7.1-1.1-.8z"
      fill="#2d353c"
    />
  </svg>
);

export default SvgPrecipitation;
