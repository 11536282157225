import React from "react";

const SvgWindSpeed = props => (
  <svg viewBox="0 0 48 48" {...props}>
    <path
      d="M46 24c0 12.1-9.8 22-22 22S2 36.1 2 24 11.8 2 24 2s22 9.9 22 22m-26.4-6.8c-5.8-.6-8.7 2.9-8.8 3l1.1 1.1s2.5-3 7.5-2.5c2.2.2 4.4.7 6.6.9h.3c1.4.1 2.9.3 4.3-.4 1.6-.7 2.4-2.2 2.1-3.9-.3-1.6-1.7-3.3-3.7-3.4-.1 0-1.8-.2-3.1 1.1l1.1 1.1c.7-.7 1.7-.6 1.8-.6 1.3.1 2.1 1.1 2.3 2.1.1.5.1 1.6-1.2 2.2-1 .5-2.3.3-3.5.2h-.3c-2.1-.2-4.4-.7-6.5-.9m7.1 16.1c1.3-1.5 1.4-3.8.2-5.3-1.6-2.2-4.8-2.4-6.1-2.4-5.2 0-8.2 2.9-8.3 3l1.1 1.1s2.7-2.5 7.2-2.5c.8 0 3.7.1 4.9 1.8.7 1 .6 2.5-.2 3.5-.8.9-2.1 1.2-3.3.8-.7-.2-1.1-.7-1.3-1.6-.1-.5-.1-1 .3-1.7-.5-.3-.9-.5-1.4-.8-.6.8-.6 2-.4 2.8.4 1.4 1.2 2.3 2.3 2.6.5.2 1 .2 1.5.2 1.3 0 2.6-.5 3.5-1.5m10.6-2.7c1.3-.9 2.2-2.7 2.3-4.5 0-1.6-.6-3-1.8-3.8-.8-.6-1.9-.9-3.3-1-.9 0-1.6.1-2.3.3-1.7.5-3.5.6-5.1.6-2.2-.1-4.5-.4-6.8-.7-5.8-.8-9.4 3.4-9.5 3.6l1.2 1s3.1-3.7 8.1-3.1c2.4.3 4.7.6 6.9.7 1.8.1 3.6-.1 5.6-.6.6-.2 1.2-.2 1.9-.2 1 0 1.8.2 2.4.7 1 .7 1.1 1.9 1.1 2.5 0 1.3-.6 2.6-1.6 3.2-.5.4-1.2.6-2 .6-.4 0-3.4-.1-3.5-3h-1.5c.1 3.6 3.3 4.5 5 4.5 1.1 0 2.1-.2 2.9-.8"
      fill="#2d353c"
    />
  </svg>
);

export default SvgWindSpeed;
