import React from "react";
import Plx from "react-plx";

function Station() {
  const parallaxStation = [
    {
      start: ".station-parallax-trigger",
      duration: "40vh",
      properties: [
        {
          startValue: 100,
          endValue: 0,
          unit: "%",
          property: "translateY"
        },
        {
          startValue: 0,
          endValue: 1,
          property: "opacity"
        }
      ]
    },
    {
      start: ".station-parallax-trigger",
      startOffset: "160vh",
      duration: "60vh",
      properties: [
        {
          startValue: 0,
          endValue: -100,
          unit: "%",
          property: "translateY"
        },
        {
          startValue: 1,
          endValue: 0,
          property: "opacity"
        }
      ]
    }
  ];
  const parallaxLeftPart = [
    {
      start: ".station-parallax-trigger",
      startOffset: "80vh",
      duration: "40vh",
      easing: [0.01, 0.37, 0.02, 0.99],
      properties: [
        {
          startValue: 0,
          endValue: -20,
          unit: "vw",
          property: "translateX"
        }
      ]
    },
    {
      start: ".station-parallax-trigger",
      startOffset: "90vh",
      duration: "30vh",
      properties: [
        {
          startValue: 1,
          endValue: 0,
          property: "opacity"
        }
      ]
    }
  ];
  return (
    <Plx className="station-container-fixed" parallaxData={parallaxStation}>
      <div className="station-container">
        <Plx className="left-part-station" parallaxData={parallaxLeftPart}>
          <img src={require("./Img/left_part.png")} alt="corn field" />
        </Plx>
        <img
          className="right-part"
          src={require("./Img/right_part.png")}
          alt="sysagria station"
        />
      </div>
    </Plx>
  );
}

export default Station;
