import React from "react";

const SvgMaps = props => (
  <svg width={40} height={40} fill="none" {...props}>
    <path fill="#fff" d="M0 0h40v40H0z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.64 21.194l-3.846-7.535c-.53-1.061-.637-2.335-.239-3.449a4.539 4.539 0 012.282-2.706A4.492 4.492 0 0127.907 7c.822 0 1.67.239 2.387.663a4.736 4.736 0 011.724 1.884c.664 1.326.664 2.786 0 4.139-.599 1.221-3.04 5.966-3.688 7.226l-.159.308c-.026.08-.08.107-.132.133-.053.027-.106.027-.133.027-.053 0-.186-.027-.265-.186zm2.92-9.604a2.616 2.616 0 00-2.627-2.627 2.616 2.616 0 00-2.627 2.627 2.616 2.616 0 002.627 2.626 2.616 2.616 0 002.626-2.626zm1.22 13.132v-8.277c.398-.823.769-1.512 1.034-1.963v15.52c0 .796-.292 1.512-.796 2.043a3.025 3.025 0 01-.796.61 3.06 3.06 0 01-1.406.345H10.025A3.023 3.023 0 017 29.976V10.183a3.023 3.023 0 013.024-3.025H24.51a5.185 5.185 0 00-1.008 1.008H20.61v6.527l-6.765 2.07v2.785h8.49l4.006 5.173h5.439zM19.576 8.141h-5.758v7.534l5.758-1.75V8.14zm-9.605 0a2.015 2.015 0 00-2.016 2.016v9.392h4.855V8.141H9.971zm11.833 12.416H7.955v4.165h17.086l-3.237-4.165zM9.971 31.965h2.84v-6.234H7.954v4.218c0 1.114.902 2.016 2.016 2.016zm3.847 0h5.97v-6.234h-5.97v6.234zm6.978 0h8.967c.292 0 .557-.053.796-.159l-4.722-6.075h-5.041v6.234zm10.56-.796c.264-.318.424-.769.424-1.22h-.027v-4.218H27.11l4.245 5.438z"
      fill="#3F3F3F"
    />
  </svg>
);

export default SvgMaps;
