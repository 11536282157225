import React from "react";

const SvgSnow = props => (
  <svg viewBox="0 0 48 48" {...props}>
    <path
      d="M24 2C11.8 2 2 11.8 2 24s9.8 22 22 22 22-9.8 22-22S36.2 2 24 2zm12.1 18.7l-1 1.8-3.2-1.9L26 24l5.9 3.5 3.2-1.9 1 1.8-2.2 1.3 3.4 2-1 1.8-3.4-2v2.7h-2v-3.9L25 25.8v7l3.2 1.9-1 1.8-2.2-1.4v4.1h-2v-4.1l-2.2 1.3-1-1.8 3.2-1.9v-7l-5.9 3.5V33h-2v-2.7l-3.4 2-1-1.8 3.4-2-2.3-1.3 1-1.8 3.2 1.9 6-3.3-5.9-3.5-3.2 1.9-1-1.8 2.3-1.3-3.4-2 1-1.8 3.4 2v-2.7h2v3.8l5.9 3.5v-7l-3.2-1.9 1-1.8 2.2 1.3V8.8h2v4.1l2.3-1.3 1 1.8-3.4 1.8v7l5.9-3.5v-3.9h2v2.7l3.4-2 1 1.8-3.4 2 2.2 1.4z"
      fill="#2d353c"
    />
  </svg>
);

export default SvgSnow;
