import React, { useState } from "react";
import SvgCrops from "./Icons/Crops";
import SvgVegetables from "./Icons/Vegetables";
// import SvgMore from "./Icons/More";
// import SvgFloriculture from "./Icons/Floriculture";
// import ExtendedNav from "./ExtendedNav";
import SvgMaps from "./Icons/Maps";
import SvgDemo from "./Icons/Demo";
import { Link } from "react-router-dom";

function Nav() {
  const navBtnSize = "35px";
  // const [activeMenu, setActiveMenu] = useState(false);

  // function toggleActiveMenu() {
  //   activeMenu ? setActiveMenu(false) : setActiveMenu(true);
  // }
  return (
    <nav>
      <ul>
        <li>
          <Link to="/crops">
            <SvgCrops width={navBtnSize} height={navBtnSize} />
            <span>Crops</span>
          </Link>
        </li>
        <li>
          <SvgVegetables width={navBtnSize} height={navBtnSize} />
          <span>Sis. integrate</span>
        </li>
        <li>
          <SvgMaps width={navBtnSize} height={navBtnSize} />
          <span>Live More</span>
        </li>
        <li>
          <SvgDemo width={navBtnSize} height={navBtnSize} />
          <span>Live Demo</span>
        </li>
        {/* <li className="more-tab">
          {activeMenu ? <ExtendedNav /> : null}
          <div className="more-tab-container" onClick={toggleActiveMenu}>
            <SvgMore width={navBtnSize} height={navBtnSize} />
            <span>{activeMenu ? "Less" : "More"}</span>
          </div>
          
        </li> */}
      </ul>
    </nav>
  );
}

export default Nav;
