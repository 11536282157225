import React from "react";
import Plx from "react-plx";
import SvgEvapotranspiration from "./Icons/Evapotranspiration";
import SvgAtmoshphericPressure from "./Icons/AtmosphericPressure";
import SvgPH from "./Icons/PH";
import SvgSoilTempHumid from "./Icons/SoilTempHumid";
import SvgElectroconductivity from "./Icons/Electroconductivity";
import SvgRelativeHumidity from "./Icons/RelativeHumidity";

function StationText2() {
  const parallaxData = [
    {
      start: ".station-parallax-trigger",
      startOffset: "80vh",
      duration: "40vh",
      properties: [
        {
          startValue: 100,
          endValue: 0,
          unit: "%",
          property: "translateY"
        },
        {
          startValue: 0,
          endValue: 1,
          property: "opacity"
        }
      ]
    },
    {
      start: ".station-parallax-trigger",
      startOffset: "160vh",
      duration: "60vh",
      properties: [
        {
          startValue: 0,
          endValue: -100,
          unit: "%",
          property: "translateY"
        },
        {
          startValue: 1,
          endValue: 0,
          property: "opacity"
        }
      ]
    }
  ];

  return (
    <Plx
      parallaxData={parallaxData}
      className="station-text-fixed"
      id="station-text2"
    >
      <div className="station-text">
        <h2>Senzori sol</h2>
        <p>
          Parametri măsurați prin eșantionare continuă, pe înălțimi și adâncimi
          diferite
        </p>
        <ul className="sensors-icons">
          <li className="sensor-icon">
            <SvgRelativeHumidity width="40px" height="40px" />
            <span>Umiditate relativă</span>
          </li>
          <li className="sensor-icon">
            <SvgEvapotranspiration width="40px" height="40px" />
            <span>Evapotranspirație</span>
          </li>
          <li className="sensor-icon">
            <SvgAtmoshphericPressure width="40px" height="40px" />
            <span>Presiune atmosferică</span>
          </li>
          <li className="sensor-icon">
            <SvgSoilTempHumid width="40px" height="40px" />
            <span>Temperatură/ Umiditate Sol</span>
          </li>
          <li className="sensor-icon">
            <SvgPH width="40px" height="40px" />
            <span>pH</span>
          </li>
          <li className="sensor-icon">
            <SvgElectroconductivity width="40px" height="40px" />
            <span>Electroconductivitate</span>
          </li>
        </ul>
        <p className="text-highlight">
          Contribuie la sănătatea culturii prin alertarea asupra întrunirii
          condițiilor propice apariției bolilor și a dăunătorilor specifici
          fiecărei culturi
        </p>
      </div>
    </Plx>
  );
}

export default StationText2;
