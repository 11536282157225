import React from "react";

const SvgDemo = props => (
  <svg width={40} height={40} fill="none" {...props}>
    <path fill="#fff" d="M0 0h40v40H0z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.273 12.185c1.49.714 2.99 1.706 4.458 2.947 2.478 2.095 4.006 4.17 4.07 4.258.265.363.265.857 0 1.22-.064.088-1.592 2.163-4.07 4.259-1.467 1.24-2.967 2.232-4.458 2.946-1.906.913-3.803 1.377-5.641 1.377s-3.736-.463-5.64-1.377C12.5 27.101 11 26.11 9.531 24.868c-2.477-2.095-4.005-4.17-4.069-4.258a1.035 1.035 0 010-1.22c.064-.088 1.592-2.163 4.07-4.258C11 13.89 12.5 12.899 13.99 12.184c1.905-.914 3.803-1.377 5.64-1.377 1.839 0 3.736.463 5.642 1.377zM7.619 20c1.517 1.827 6.398 7.123 12.013 7.123 5.614 0 10.495-5.295 12.013-7.123-1.518-1.828-6.399-7.123-12.013-7.123-5.615 0-10.496 5.295-12.013 7.123zm6.783 0a5.236 5.236 0 015.23-5.23 5.236 5.236 0 015.23 5.23 5.236 5.236 0 01-5.23 5.23 5.236 5.236 0 01-5.23-5.23zm2.069 0a3.165 3.165 0 003.16 3.161 3.165 3.165 0 003.162-3.16 3.165 3.165 0 00-3.161-3.162A3.165 3.165 0 0016.47 20z"
      fill="#3F3F3F"
    />
  </svg>
);

export default SvgDemo;
