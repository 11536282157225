import React from "react";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaYoutube,
  FaAngleDoubleUp
} from "react-icons/fa";
import SvgEU from "./Icons/Eu";
import { IconContext } from "react-icons";

function Footer() {
  return (
    <IconContext.Provider value={{ size: "24px" }}>
      <footer>
        <a className="back-to-top" href="#top">
          <FaAngleDoubleUp className="back-arrow" />
          <span>Mergi sus</span>
        </a>
        <div className="social-icons">
          <a
            href="https://www.facebook.com/syswinsolutions/"
            className="icon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://www.linkedin.com/company/syswinsolutions/"
            className="icon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedinIn />
          </a>
          <a
            href="https://www.youtube.com/channel/UCMjc4tTNMdmQo41aDr49UUg"
            className="icon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube />
          </a>
        </div>
        <ul className="contact-info">
          <li>
            <span>Adresă:</span>
            <span>
              Str. Biharia, Nr. 26, Et. 3, Sector 1, București, România
            </span>
          </li>
          <li>
            <span>Telefon:</span>
            <span>+40 0314 251 298</span>
          </li>
          <li>
            <span>Mobil:</span>
            <span>+40 771 339 504</span>
          </li>
          <li>
            <span>E-Mail:</span>
            <span>info@syswinsolutions.com</span>
          </li>
          <li>
            <span>Web:</span>
            <span>
              <a
                href="https://www.syswinsolutions.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.syswinsolutions.com
              </a>
            </span>
          </li>
          <li style={{ marginTop: "30px", alignItems: "center" }}>
            <span>
              <SvgEU width="75px" />
            </span>
            <span style={{ width: "70%", fontSize: "0.7rem" }}>
              This project has received funding from the European Union’s
              Horizon 2020 research and innovation programme under grant
              agreement No SMEINST-876635.
            </span>
          </li>
        </ul>
      </footer>
    </IconContext.Provider>
  );
}

export default Footer;
