import React from "react";
import StationParallax from "./StationParallax";
// import ScrollDots from "./ScrollDots";
import DeviceParallax from "./DeviceParallax";
import Logo from "./Icons/Logo";

function Main() {
  return (
    <div className="main">
      <div id="top" className="hero-image">
        <div className="logo">
          <Logo />
        </div>

        <div className="hero-text">
          <h1>Tehnologie pentru agricultura viitorului</h1>
          <p>
            SysAgria colectează date valoroase direct de pe câmp și le trimite
            pe telefonul tău mobil. Oferind posibilitatea de a monitoriza
            culturile oriunde în orice moment, soluția noastră susține un
            randament mai ridicat și o calitate optimă
          </p>
        </div>
      </div>
      <div className="device-parallax-trigger" />
      <DeviceParallax />
      <div className="station-parallax-trigger" />
      {/* <div className="circles-parallax-trigger" /> */}
      <StationParallax />
    </div>
  );
}
export default Main;
