import React from "react";

const SvgTemperature = props => (
  <svg viewBox="0 0 48 48" {...props}>
    <path
      d="M46 24c0 12.2-9.8 22-22 22S2 36.2 2 24 11.8 2 24 2s22 9.8 22 22m-23.6-9.9H24V10h-1.6v4.1zm-10.8 7.4h4.1v-1.6h-4.1v1.6zm3.4-9.3l-1.2 1.2 2.9 2.9 1.2-1.2-2.9-2.9zm-1.2 17.5l1.2 1.2 2.9-2.9-1.2-1.2-2.9 2.9zM24 15.8c-.3 0-.5-.1-.8-.1-3.2 0-5.8 2.6-5.8 5.8 0 3.2 2.6 5.8 5.8 5.8.3 0 .6 0 .8-.1V15.8zm6.6 12.4V14.1H29v14.1c-1.4.4-2.5 1.6-2.5 3.2 0 1.8 1.5 3.3 3.3 3.3 1.8 0 3.3-1.5 3.3-3.3 0-1.5-1.1-2.8-2.5-3.2m-.8 8.2c-2.7 0-4.9-2.2-4.9-4.9 0-1.8 1-3.4 2.5-4.3v-13c0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5v13c1.5.9 2.5 2.4 2.5 4.3-.2 2.7-2.4 4.9-5.1 4.9m4.1-10.1V14.1c0-2.3-1.8-4.1-4.1-4.1s-4.1 1.8-4.1 4.1v12.2c-1.5 1.2-2.5 3.1-2.5 5.1 0 3.6 3 6.6 6.6 6.6 3.6 0 6.6-3 6.6-6.6 0-2.1-1-3.9-2.5-5.1"
      fill="#2d353c"
    />
  </svg>
);

export default SvgTemperature;
