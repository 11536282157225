import React from "react";

const SvgElectroconductivity = props => (
  <svg viewBox="0 0 48 48" {...props}>
    <path
      d="M24 2C11.8 2 2 11.8 2 24s9.8 22 22 22 22-9.8 22-22S36.2 2 24 2zm7 18L18.1 38l4.2-14h-4.2L22 10h9l-4.5 10H31z"
      fill="#2d353c"
    />
  </svg>
);

export default SvgElectroconductivity;
