import React, { useEffect } from "react";

function Crops() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ scrollBehavior: "initial" }}>
      {/* <Link to="/">
        <div className="back-btn"> Back to home</div>
      </Link> */}
      <h1>Crop types</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolore ea
        consequatur earum accusamus, laboriosam vitae iusto ex autem, tenetur
        quod cumque modi, suscipit sed! Cupiditate enim ad temporibus iure
        architecto?
      </p>
      <div className="crops-container">
        <div className="crop">
          <img src={require("./Img/crop.jpg")} alt="crop" />
          <span>Crop type name</span>
        </div>
        <div className="crop">
          <img src={require("./Img/crop.jpg")} alt="crop" />
          <span>Crop type name</span>
        </div>
        <div className="crop">
          <img src={require("./Img/crop.jpg")} alt="crop" />
          <span>Crop type name</span>
        </div>
        <div className="crop">
          <img src={require("./Img/crop.jpg")} alt="crop" />
          <span>Crop type name</span>
        </div>
        <div className="crop">
          <img src={require("./Img/crop.jpg")} alt="crop" />
          <span>Crop type name</span>
        </div>
        <div className="crop">
          <img src={require("./Img/crop.jpg")} alt="crop" />
          <span>Crop type name</span>
        </div>
      </div>
      {/* <Link to="/">
        <div className="back-btn"> Back to home</div>
      </Link> */}
    </div>
  );
}

export default Crops;
