import React from "react";

const SvgWindDirection = props => (
  <svg viewBox="0 0 48 48" {...props}>
    <path
      d="M46 24c0 12.2-9.8 22-22 22S2 36.2 2 24 11.8 2 24 2s22 9.8 22 22m-18-7.6c-2.4 0-4.4-1.6-7.4-1.6-1.1 0-2.1.2-3 .5.1-.3.2-.7.2-1.1-.1-1.2-1.2-2.2-2.4-2.2-1.4-.1-2.6 1.1-2.6 2.5 0 .8.4 1.6 1.1 2.1v17.2c0 .6.5 1.1 1.1 1.1h.7c.6 0 1.1-.5 1.1-1.1v-4.2c1.3-.5 2.8-1 5.1-1 2.4 0 4.4 1.6 7.4 1.6 2.1 0 3.9-.7 5.5-1.8.4-.3.6-.7.6-1.2V16.3c0-1-1.1-1.7-2-1.3-1.7.7-3.6 1.4-5.4 1.4"
      fill="#2d353c"
    />
  </svg>
);

export default SvgWindDirection;
