import React from "react";

const SvgSolarRadiation = props => (
  <svg viewBox="0 0 48 48" {...props}>
    <path
      d="M46 24c0 12.2-9.8 22-22 22S2 36.2 2 24 11.8 2 24 2s22 9.8 22 22m-15.2 0c0-3.8-3-6.8-6.8-6.8-3.7 0-6.8 3.1-6.8 6.8s3 6.8 6.8 6.8c3.7 0 6.8-3 6.8-6.8m-7.6-9.4h1.7V9.4h-1.7v5.2zm0 24h1.7v-5.1h-1.7v5.1zM9.6 24.9h5.1v-1.7H9.6v1.7zm23.7 0h5.1v-1.7h-5.1v1.7zM15 13.7l-1.2 1.2 3 3 1.2-1.2-3-3zm16.2 16.4L30 31.3l3 3 1.2-1.2-3-3zm-17.4 3l1.2 1.2 3-3-1.2-1.2-3 3zM30 16.7l1.2 1.2 3-3-1.2-1.2-3 3z"
      fill="#2d353c"
    />
  </svg>
);

export default SvgSolarRadiation;
