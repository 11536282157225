import React from "react";

const SvgPH = props => (
  <svg
    id="pH_svg__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 48 48"
    xmlSpace="preserve"
    {...props}
  >
    <style>{".pH_svg__st0{fill:#2d353c}"}</style>
    <path
      className="pH_svg__st0"
      d="M16.8 20.7c-.8 0-1.4.3-1.7.9V26c.3.6.9.9 1.7.9 1.1 0 1.7-1 1.7-2.9v-.4c0-1.9-.5-2.9-1.7-2.9z"
    />
    <path
      className="pH_svg__st0"
      d="M24 2C11.8 2 2 11.8 2 24s9.8 22 22 22 22-9.8 22-22S36.2 2 24 2zm-2 21.9c0 1.7-.4 3.1-1.2 4.2-.8 1-1.8 1.6-3.2 1.6-1 0-1.9-.4-2.5-1.1v5.2h-3.5V18.2h3.3l.1 1c.7-.8 1.6-1.2 2.6-1.2 1.4 0 2.5.5 3.2 1.5.8 1 1.2 2.4 1.2 4.2v.2zm14.4 5.5h-3.6v-6.3h-5.4v6.3h-3.6V14.3h3.6v6h5.4v-6h3.6v15.1z"
    />
  </svg>
);

export default SvgPH;
