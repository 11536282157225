import React from "react";

const SvgEvapotranspiration = props => (
  <svg viewBox="0 0 48 48" {...props}>
    <path
      d="M29.6 12.1c.5.7.7 1.5.6 2.3-.1.8-.5 1.5-1 2.1-1.2 1.1-3 1.1-4.2 0-.6-.5-1-1.3-1.1-2.1-.1-.8.1-1.6.6-2.3L27 8.2l2.6 3.9zM46 24c0 12.2-9.8 22-22 22S2 36.2 2 24 11.8 2 24 2s22 9.8 22 22zM29.9 11.8l-2.8-4.2c0-.1-.1-.1-.2-.1s-.1 0-.2.1L24 11.7c-.5.7-.7 1.6-.6 2.5.1.9.5 1.7 1.2 2.3.7.6 1.5.9 2.4.9.8 0 1.7-.3 2.4-.9.7-.6 1.1-1.4 1.2-2.3 0-.7-.2-1.6-.7-2.4zM14.2 31.4s3.3-9.8 13-10.2c0 0-12.2 3.9-12.5 17.6h2.5s-.4-3.7.4-5.4c0 0 7.4 2.2 9.9-4.2s6.4-8.4 6.4-8.4-21-9.3-19.7 10.6"
      fill="#2d353c"
    />
  </svg>
);

export default SvgEvapotranspiration;
