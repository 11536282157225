import React from "react";
import Station from "./Station";
import StationText from "./StationText";
import StationText2 from "./StationText2";

function StationParallax() {
  return (
    <>
      <Station />
      <StationText />
      <StationText2 />
    </>
  );
}
export default StationParallax;
